<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <template v-for="block in blocks" :key="block.typeHandle">
    <BlockHeaderSection
      v-if="block.typeHandle === 'BlockHeaderSection'"
      :block="block"
      :globals="globals"
    />
    <BlockTextWithImageV3
      v-else-if="block.typeHandle === 'BlockTextWithImageV3'"
      :block="block"
      :globals="globals"
    />
    <BlockTextWithImageV2
      v-else-if="block.typeHandle === 'BlockTextWithImageV2'"
      :block="block"
      :globals="globals"
    />
    <BlockSingleText
      v-else-if="block.typeHandle === 'BlockSingleText'"
      :block="block"
      :globals="globals"
    />
    <BlockSingleButton
      v-else-if="block.typeHandle === 'BlockSingleButton'"
      :block="block"
      :globals="globals"
    />
    <BlockRedactorSimple
      v-else-if="block.typeHandle === 'BlockRedactorSimple'"
      :block="block"
      :globals="globals"
    />
    <BlockAccordion
      v-else-if="block.typeHandle === 'BlockAccordion'"
      :block="block"
      :globals="globals"
    />
    <BlockRelatedProducts
      v-else-if="block.typeHandle === 'BlockRelatedProducts'"
      :block="block"
      :globals="globals"
    />
    <BlockProductSkuOrId
      v-else-if="block.typeHandle === 'BlockProductSkuOrId'"
      :block="block"
      :globals="globals"
    />
    <BlockButton
      v-else-if="block.typeHandle === 'BlockButton'"
      :block="block"
      :globals="globals"
    />
    <BlockChosenArticleSections
      v-else-if="block.typeHandle === 'BlockChosenArticleSections'"
      :block="block"
      :globals="globals"
    />
    <BlockChosenArticle
      v-else-if="block.typeHandle === 'BlockChosenArticle'"
      :block="block"
      :globals="globals"
    />
    <BlockSectionBlock
      v-else-if="block.typeHandle === 'BlockSectionBlock'"
      :block="block"
      :globals="globals"
      :page-type="pageType"
    />
    <BlockSingleTrustBadge
      v-else-if="block.typeHandle === 'BlockSingleTrustBadge'"
      :block="block"
      :globals="globals"
    />
    <BlockHowFvWorks
      v-else-if="block.typeHandle === 'BlockHowFvWorks'"
      :block="block"
      :globals="globals"
    />
    <BlockHowFvWorksVideo
      v-else-if="block.typeHandle === 'BlockHowFvWorksVideo'"
      :block="block"
      :globals="globals"
    />
    <BlockSplitHeroImage
      v-else-if="block.typeHandle === 'BlockSplitHeroImage'"
      :block="block"
      :globals="globals"
    />
    <BlockInsuranceCompanyDropdown
      v-else-if="block.typeHandle === 'BlockInsuranceCompanyDropdown'"
      :block="block"
      :globals="globals"
    />
    <BlockTestimonials
      v-else-if="block.typeHandle === 'BlockTestimonials'"
      :block="block"
      :globals="globals"
    />
    <BlockTestimonial
      v-else-if="block.typeHandle === 'BlockTestimonial'"
      :block="block"
      :globals="globals"
    />
    <BlockAppBadgesSection
      v-else-if="block.typeHandle === 'BlockAppBadgesSection'"
      :block="block"
      :globals="globals"
    />
    <BlockReviews
      v-else-if="block.typeHandle === 'BlockReviews'"
      :block="block"
      :globals="globals"
    />
    <BlockVeterinarians
      v-else-if="block.typeHandle === 'BlockVeterinarians'"
      :page-type="pageType"
      :globals="globals"
    />
    <BlockImage
      v-else-if="block.typeHandle === 'BlockImage'"
      :block="block"
      :globals="globals"
    />
    <BlockPriceSection
      v-else-if="block.typeHandle === 'BlockPriceSection'"
      :block="block"
      :globals="globals"
    />
    <BlockColumns
      v-else-if="block.typeHandle === 'BlockColumns'"
      :block="block"
      :globals="globals"
    />
    <BlockColumn
      v-else-if="block.typeHandle === 'BlockColumn'"
      :block="block"
      :globals="globals"
    />
    <BlockVideo
      v-else-if="block.typeHandle === 'BlockVideo'"
      :block="block"
      :globals="globals"
    />
    <BlockImageList
      v-else-if="block.typeHandle === 'BlockImageList'"
      :block="block"
      :globals="globals"
    />
    <BlockImageListItem
      v-else-if="block.typeHandle === 'BlockImageListItem'"
      :block="block"
      :globals="globals"
    />
    <BlockInsurances
      v-else-if="block.typeHandle === 'BlockInsurances'"
      :block="block"
      :globals="globals"
    />
    <BlockInsuranceCompanyListV2
      v-else-if="block.typeHandle === 'BlockInsuranceCompanyListV2'"
      :block="block"
      :globals="globals"
    />
    <BlockWhyFirstvet
      v-else-if="block.typeHandle === 'BlockWhyFirstvet'"
      :block="block"
      :globals="globals"
    />
    <BlockSymptomListBlock
      v-else-if="block.typeHandle === 'BlockSymptomListBlock'"
      :block="block"
      :globals="globals"
    />
    <BlockShopBanners
      v-else-if="block.typeHandle === 'BlockShopBanners'"
      :block="block"
      :globals="globals"
    />
    <BlockShopIcons
      v-else-if="block.typeHandle === 'BlockShopIcons'"
      :block="block"
      :globals="globals"
    />
    <BlockCtaWidgetNewsletterSection
      v-else-if="block.typeHandle === 'BlockCtaWidgetNewsletterSection'"
      :block="block"
      :globals="globals"
    />
    <BlockPaymentAndShipmentLogos
      v-else-if="block.typeHandle === 'BlockPaymentAndShipmentLogos'"
      :block="block"
      :globals="globals"
    />

    <template v-else-if="block.typeHandle === 'BlockNextAvailableTime'">
      <ClientOnly
        ><NextAvailableTime
          :time-slot-delay="globals?.timeSlotDelay?.number || 10"
          class="w-full !text-center !text-dynamic-primary [&_span]:text-primary-default"
        ></NextAvailableTime>
        <template #fallback>
          <p
            class="text-dynamic -mt-2 mb-4 w-full text-center text-base text-black/87 md:m-0 md:text-left md:text-white"
          >
            &nbsp;
          </p>
        </template>
      </ClientOnly>
    </template>
  </template>
</template>

<script setup lang="ts">
defineProps({
  blocks: {
    type: Array,
    default: () => [],
  },
  globals: {
    type: Object,
    default: () => {},
  },
  pageType: {
    type: String,
    default: 'page',
  },
});

const BlockHeaderSection = defineAsyncComponent(
  () => import('./blocks/BlockHeaderSection.vue')
);
const BlockTextWithImageV3 = defineAsyncComponent(
  () => import('./blocks/BlockTextWithImageV3.vue')
);
const BlockTextWithImageV2 = defineAsyncComponent(
  () => import('./blocks/BlockTextWithImageV2.vue')
);
const BlockSingleText = defineAsyncComponent(
  () => import('./blocks/BlockSingleText.vue')
);
const BlockSingleButton = defineAsyncComponent(
  () => import('./blocks/BlockSingleButton.vue')
);
const BlockRedactorSimple = defineAsyncComponent(
  () => import('./blocks/BlockRedactorSimple.vue')
);
const BlockAccordion = defineAsyncComponent(
  () => import('./blocks/BlockAccordion.vue')
);
const BlockRelatedProducts = defineAsyncComponent(
  () => import('./blocks/BlockRelatedProducts.vue')
);
const BlockProductSkuOrId = defineAsyncComponent(
  () => import('./blocks/BlockProductSkuOrId.vue')
);
const BlockButton = defineAsyncComponent(
  () => import('./blocks/BlockButton.vue')
);
const BlockChosenArticleSections = defineAsyncComponent(
  () => import('./blocks/BlockChosenArticleSections.vue')
);
const BlockChosenArticle = defineAsyncComponent(
  () => import('./blocks/BlockChosenArticle.vue')
);
const BlockSectionBlock = defineAsyncComponent(
  () => import('./blocks/BlockSectionBlock.vue')
);
const BlockSingleTrustBadge = defineAsyncComponent(
  () => import('./blocks/BlockSingleTrustBadge.vue')
);
const BlockHowFvWorks = defineAsyncComponent(
  () => import('./blocks/BlockHowFvWorks.vue')
);
const BlockHowFvWorksVideo = defineAsyncComponent(
  () => import('./blocks/BlockHowFvWorksVideo.vue')
);
const BlockSplitHeroImage = defineAsyncComponent(
  () => import('./blocks/BlockSplitHeroImage.vue')
);
const BlockInsuranceCompanyDropdown = defineAsyncComponent(
  () => import('./blocks/BlockInsuranceCompanyDropdown.vue')
);
const BlockTestimonials = defineAsyncComponent(
  () => import('./blocks/BlockTestimonials.vue')
);
const BlockTestimonial = defineAsyncComponent(
  () => import('./blocks/BlockTestimonial.vue')
);
const BlockAppBadgesSection = defineAsyncComponent(
  () => import('./blocks/BlockAppBadgesSection.vue')
);
const BlockReviews = defineAsyncComponent(
  () => import('./blocks/BlockReviews.vue')
);
const BlockVeterinarians = defineAsyncComponent(
  () => import('./blocks/BlockVeterinarians.vue')
);
const BlockImage = defineAsyncComponent(
  () => import('./blocks/BlockImage.vue')
);
const BlockPriceSection = defineAsyncComponent(
  () => import('./blocks/BlockPriceSection.vue')
);
const BlockColumns = defineAsyncComponent(
  () => import('./blocks/BlockColumns.vue')
);
const BlockColumn = defineAsyncComponent(
  () => import('./blocks/BlockColumn.vue')
);
const BlockVideo = defineAsyncComponent(
  () => import('./blocks/BlockVideo.vue')
);
const BlockImageList = defineAsyncComponent(
  () => import('./blocks/BlockImageList.vue')
);
const BlockImageListItem = defineAsyncComponent(
  () => import('./blocks/BlockImageListItem.vue')
);
const BlockInsurances = defineAsyncComponent(
  () => import('./blocks/BlockInsurances.vue')
);
const BlockInsuranceCompanyListV2 = defineAsyncComponent(
  () => import('./blocks/BlockInsuranceCompanyListV2.vue')
);
const BlockWhyFirstvet = defineAsyncComponent(
  () => import('./blocks/BlockWhyFirstvet.vue')
);
const BlockSymptomListBlock = defineAsyncComponent(
  () => import('./blocks/BlockSymptomListBlock.vue')
);
const BlockShopBanners = defineAsyncComponent(
  () => import('./blocks/BlockShopBanners.vue')
);
const BlockShopIcons = defineAsyncComponent(
  () => import('./blocks/BlockShopIcons.vue')
);
const BlockCtaWidgetNewsletterSection = defineAsyncComponent(
  () => import('./blocks/BlockCtaWidgetNewsletterSection.vue')
);
const BlockPaymentAndShipmentLogos = defineAsyncComponent(
  () => import('./blocks/BlockPaymentAndShipmentLogos.vue')
);

const NextAvailableTime = defineAsyncComponent(
  () =>
    import(
      '@brand/components/domain/editorial/pageBuilder/blocks/components/NextAvailableTime.vue'
    )
);
</script>

<style>
/** 
 * TailwindCSS Dynamic Content Styling:
 * - Background classes are safelisted in Tailwind to prevent purging during production builds.
 * - Depending on the background color, text colors adjust for readability.
 * - Apply the appropriate 'text-dynamic-*' class to adjust text color based on the background.
 */

:root {
  --dynamic-primary: theme('colors.supporting.black-87');
  --dynamic-secondary: theme('colors.supporting.black-54');
}

.bg-background-black,
.bg-background-dark-blue,
.bg-background-blue,
.bg-background-dark-brown,
.bg-background-black-87,
.bg-background-black-54,
.bg-background-brown,
.bg-background-red,
.bg-background-orange,
.bg-background-green {
  --dynamic-primary: white;
  --dynamic-secondary: white;
}

.bg-white,
.bg-background-light-blue,
.bg-background-vlight-blue,
.bg-background-light,
.bg-background-beige,
.bg-background-beige-alt,
.bg-background-extra-light,
.bg-background-pink,
.bg-background-light-green,
.bg-background-light-grey,
.bg-background-light-brown,
.bg-background-black-26 {
  --dynamic-primary: theme('colors.supporting.black-87');
  --dynamic-secondary: theme('colors.supporting.black-54');
}
</style>
